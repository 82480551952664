<template>
    <div class="shop-cart-page">
        <GeekQooSearch></GeekQooSearch>
        <div class="top-title">
            <p>购物车</p>
        </div>
        <div class="cart-container">
            <div v-if="goodsList.length>0" class="item-content">
                <div class="list-wrapper">
                    <div v-for="(item,index) in goodsList" :key="index" class="list-item">
                        <div class="item-checkbox">
                            <van-checkbox v-model="item.checked" checked-color="#F96719"
                                          icon-size="0.14rem" @click="checkboxChange(item)"></van-checkbox>
                        </div>
                        <div :style="`background: url('${item.thumb}')`" class="item-image"
                             @click="toDetail(item)">
                            <p v-if="item.on_sale===0" class="removed">已下架</p>
                        </div>
                        <div class="item-info" @click="toDetail(item)">
                            <p class="p1">{{ item.title }}</p>
                            <p class="p2">
                                <span>￥{{ item.price }}</span>
                            </p>
                        </div>
                        <van-stepper v-model="item.count" class="item-stepper" input-width="0.32rem" @change="goodsCountChange"/>
                        <a class="item-button" @click="delGood(item)">
                            <van-icon name="delete"/>
                        </a>
                    </div>
                </div>
            </div>
            <div v-else class="item-empty">
                <van-empty description="购物车里暂时没有商品哦~">
                    <van-button class="back-button" color="#F28246" to="/category-list">
                        去商城挑选
                    </van-button>
                </van-empty>
            </div>
        </div>
        <div class="cart-toolbar">
            <div class="item-left">
                <van-checkbox v-model="checkedAllstate" checked-color="#F96719" icon-size="0.14rem" @click="checkAll()">
                    全选
                </van-checkbox>
            </div>
            <p class="item-middle">
                总计：
                <span>￥{{ totalPrice }}</span>
            </p>
            <a v-if="app.userData.createOrder" class="item-right" @click="openCustomerDialog()">立即结算</a>
        </div>
        <van-dialog v-model="customerData.show" :show-confirm-button="false" close-on-click-overlay
                    get-container=".shop-cart-page">
            <div class="customer-dialog">
                <van-field
                    v-model="customerData.customer"
                    :right-icon="customerList.list.length>0?'arrow-down':''"
                    left-icon="friends-o"
                    placeholder="请输入客户姓名"
                    @click-right-icon="openCustomerList()"
                />
                <van-field
                    v-model="customerData.address"
                    left-icon="wap-home-o"
                    placeholder="请输入客户楼盘号"
                />
                <van-button block color="#F54A4A" size="small" type="primary" @click="createOrder()">确认提交</van-button>
            </div>
        </van-dialog>
        <van-popup v-model="customerList.show" get-container=".shop-cart-page" position="bottom">
            <van-picker
                :columns="customerList.list"
                item-height="0.44rem"
                show-toolbar
                title="选择客户"
                value-key="name"
                @cancel="customerList.show=false"
                @confirm="customerListConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
// 引入mixins
import mixins_config from "@/mixins/config.js";
// 引入组件
import GeekQooSearch from "@/components/GeekQooSearch"
// 引入接口
import {CREATE_ORDERS, DEL_CARTS, GET_CARTS, GET_CUSTOMERS} from "@/api/dataProvider";

export default {
    name: "ShopCart",
    mixins: [mixins_config],
    components: {
        GeekQooSearch
    },
    data() {
        return {
            goodsList: [],
            // 购物车是否全选
            checkedAllstate: "",
            // 购物车总价
            totalPrice: 0,
            // 顾客输入信息
            customerData: {
                show: false,
                customer: "",
                address: ""
            },
            // 顾客列表
            customerList: {
                show: false,
                list: []
            }
        };
    },
    created() {
        this.app.tabActive = 2;
    },
    mounted() {
        this.getGoodsList()
    },
    methods: {
        // 获取商品列表
        getGoodsList() {
            let that = this
            GET_CARTS({}, function (res) {
                console.log(res.data.data.data)
                res.data.data.data.map((item) => {
                    item.checked = false
                })
                that.goodsList = res.data.data.data
                that.getTotalPrice();
                that.isCheckedAll();
            })
        },
        getTotalPrice() {
            // 计算总价
            this.goodsList.map(item => {
                if (item.checked) {
                    this.totalPrice += (Number(item.price)*Number(item.count));
                }
            })
        },
        // 刷新总价
        updateTotalPrice() {
            this.totalPrice = 0
            this.goodsList.map((item) => {
                if (item.checked) {
                    this.totalPrice += (Number(item.price)*Number(item.count));
                }
            })
        },
        // 判断是否全选
        isCheckedAll() {
            this.goodsList.length > 0 ? this.checkedAllstate = true : this.checkedAllstate = false
            for (let i = 0; i < this.goodsList.length; i++) {
                if (!this.goodsList[i].checked) {
                    this.checkedAllstate = false;
                    break;
                }
            }
        },
        // 更改商品选中状态
        checkboxChange(item) {
            this.isCheckedAll();
            this.updateTotalPrice()
        },
        // 商品数量变化事件
        goodsCountChange(val){
            this.updateTotalPrice()
        },
        // 删除购物车商品
        delGood(item) {
            let that = this
            that.$dialog.confirm({
                title: "提示",
                message: "确认要删除该商品吗？",
            }).then(() => {
                DEL_CARTS(
                    {cargos: item.id},
                    function (res) {
                        if (res.data.code == 0) {
                            that.getGoodsList()
                        } else {
                            that.$notify({type: "danger", message: "删除失败，请重试！"});
                        }
                    }
                )
            }).catch(() => {
            })
        },
        // 全选
        checkAll() {
            if (this.checkedAllstate) {
                this.goodsList.map(item => {
                    item.checked = true;
                })
            } else {
                this.goodsList.map(item => {
                    item.checked = false;
                })
            }
            this.updateTotalPrice()
        },
        // 打开填写顾客信息的弹窗
        openCustomerDialog() {
            let that = this
            that.customerData.show = true
            GET_CUSTOMERS(
                {},
                function (res) {
                    if (res.data.code == 0) {
                        that.customerList.list = res.data.data.data
                    }
                }
            )
        },
        // 打开顾客列表
        openCustomerList() {
            this.customerList.show = true
        },
        // 顾客列表选择顾客
        customerListConfirm(value) {
            this.customerData.customer = value.name
            this.customerData.address = value.address
            this.customerList.show = false
        },
        // 结算
        createOrder() {
            let that = this
            if (!that.customerData.customer || !that.customerData.address) {
                that.$dialog.alert({
                    title: "提示",
                    message: "请输入客户信息",
                })
                return false;
            }
            that.customerData.show = false
            // 筛选出选中的商品
            let checkedList = that.goodsList.filter((item) => {
                return item.checked
            })
            // 商品数组
            let goodArr = []
            checkedList.map((item) => {
                goodArr.push({
                    id: item.id,
                    count: item.count
                })
            })
            if (goodArr.length > 0) {
                CREATE_ORDERS(
                    {
                        goods_data: goodArr,
                        customer: that.customerData.customer,
                        address: that.customerData.address
                    },
                    function (res) {
                        if (res.data.code === 0) {
                            that.getGoodsList()
                            that.$router.push({
                                name: "Checkout",
                                params: {id: res.data.data.id}
                            })
                        } else {
                            that.$toast("创建失败，请检查后重试！");
                        }
                    }
                )
            } else {
                that.$toast("请选择商品结算！");
            }
        },
        // 跳转商品详情
        toDetail(item) {
            this.$router.push({
                name: "GoodDetail",
                params: {id: item.id}
            })
        }
    }
}

</script>

<style lang="scss">
.shop-cart-page {
    padding: 0 0 92px 0;

    .top-title {
        background: #F96719;

        p {
            line-height: 35px;
            text-align: center;
            font-size: 10px;
            color: #fff;
        }
    }

    .cart-container {
        .item-empty {
            .back-button {
                width: 300px;
            }
        }

        .item-content {
            .list-wrapper {
                .list-item {
                    display: flex;
                    align-items: center;
                    padding: 14px;
                    border-bottom: 1px solid #EEEEEE;

                    &:last-child {
                        border-bottom: none;
                    }

                    .item-checkbox {
                    }

                    .item-image {
                        position: relative;
                        width: 70px;
                        height: 52px;
                        margin-left: 14px;
                        border-radius: 3px;
                        background-repeat: no-repeat !important;
                        background-size: cover !important;
                        background-position: center !important;
                        overflow: hidden;

                        .removed {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            line-height: 52px;
                            background: rgba(#000, 0.7);
                            text-align: center;
                            font-size: 12px;
                            color: #fff;
                        }
                    }

                    .item-info {
                        margin-left: 14px;

                        .p1 {
                            width: 105px;
                            font-size: 10px;
                            color: #2D2D2D;
                        }

                        .p2 {
                            margin-top: 10px;
                            font-size: 10px;
                            color: #999999;

                            span {
                                font-weight: bold;
                                color: #F54A4A;
                            }
                        }
                    }

                    .item-stepper {
                        margin-left: auto;
                    }

                    .item-button {
                        display: block;
                        margin-left: 5px;
                        font-size: 25px;
                        color: #F54A4A;

                        i {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .cart-toolbar {
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 50px;
        left: 0;
        width: 100%;
        padding: 7px 14px;
        box-shadow: 0px -1px 2px rgba(#000, 0.16);
        background: #fff;

        .item-left {
            margin-right: auto;

            .van-checkbox {
                .van-checkbox__label {
                    font-size: 10px;
                    color: #2D2D2D;
                }
            }
        }

        .item-middle {
            margin-right: 20px;
            font-size: 10px;
            color: #2D2D2D;

            span {
                color: #F54A4A;
            }
        }

        .item-right {
            width: 69px;
            line-height: 28px;
            background: #F54A4A;
            border-radius: 3px;
            text-align: center;
            font-size: 10px;
            color: #FFFFFF;
        }
    }

    .customer-dialog {
        padding: 5px 0;

        .van-field {
            .van-field__value {
                font-size: 10px;
            }
        }

        .van-button {
            width: calc(100% - 32px);
            margin: 10px 16px
        }
    }
}
</style>